import React from "react"
import Head from "../components/global/head"
import HeaderBreadcrumbs from "../components/header_breadcrumbs"

import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import ContentBoundary from "../components/content/boundary"
import ModuleRenderer from "../components/modules"
import HeroIntroBanner from "../components/modules/hero_intro_banner"

const StoryNewsDetailTemplate = ({ data, location }) => {
  const {
    bodyCopyNode,
    bodycopy,
    date,
    title,
    slug,
    modules,
    shortDescription,
    storyListing,
  } = data.datoCmsPost

  const pageRoot = {
    name: "Stories & News",
    path: "/stories-and-news",
  }
  const breadcrumbs = [
    {
      name: title,
      path: location.pathname,
    },
  ]

  const handleHeroImage = () => {
    if (modules.length > 0) {
      return modules[0].backgroundImage
    } else {
      return null
    }
  }
  const handleHasImage = () => {
    if (modules.length > 0) {
      if (modules[0].backgroundImage) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <>
      <Head
        title={`Stories & News - ${title}`}
        url={`https://www.choosetap.com.au/stories-and-news/${slug}`}
        keywords=""
        description=""
      />

      <section className="pages__child">
        <HeroIntroBanner
          className="newsStoryDetail"
          date={date}
          title={title}
          shortDescription={shortDescription}
          isStoryListing={storyListing}
          sectionName={`${storyListing ? "story" : "news"}`}
          image={handleHeroImage()}
          hasImage={handleHasImage()}
          themeColor="grey"
          hasBreadcrumbs={true}
        >
          <HeaderBreadcrumbs
            isProgram={false}
            root={pageRoot}
            crumbs={breadcrumbs}
          />
        </HeroIntroBanner>

        <main>
          <ContentBoundary>
            <ReactMarkdown source={bodycopy} />
            <ModuleRenderer isLandingInstance={false} modules={modules} />
          </ContentBoundary>
        </main>
      </section>
    </>
  )
}

export default StoryNewsDetailTemplate

export const query = graphql`
  query($slug: String!) {
    datoCmsPost(slug: { eq: $slug }) {
      date
      title
      slug
      storyListing
      shortDescription
      modules {
        __typename
        ... on DatoCmsBodyCopy {
          bodyCopyNode {
            childMarkdownRemark {
              html
            }
          }
        }
        __typename
        ... on DatoCmsHero {
          id
          backgroundImage {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsImageTextBlock {
          id
          alignRight
          bodyCopy
          title
          image {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsImageBlock {
          id
          legend
          title
          image {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsQuoteBlock {
          id
          text
          image {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsVideoBlock {
          id
          legend
          title
          youtubeUrl
        }
      }
    }
  }
`
